var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "480px", height: "auto" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.layerClose()
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.NEWB010150.001"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("form", { attrs: { id: "vocForm" } }, [
            _c("div", { staticClass: "tit_txt" }, [
              _vm._v(_vm._s(_vm.$t("msg.NEWB010150.002"))),
            ]),
            _c("div", { staticClass: "desc_txt mt15" }, [
              _vm._v(_vm._s(_vm.$t("msg.NEWB010150.003"))),
            ]),
            _c("div", { staticClass: "service_form_wrap" }, [
              _c("ul", { staticClass: "grid", attrs: { id: "contKndCdBox" } }, [
                _c("li", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.contKndCd,
                        expression: "formData.contKndCd",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "contKndCd",
                      id: "contKndCd_1",
                      value: "02",
                    },
                    domProps: { checked: _vm._q(_vm.formData.contKndCd, "02") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.formData, "contKndCd", "02")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "contKndCd_1" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.NEWB010150.004"))),
                  ]),
                ]),
                _c("li", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.contKndCd,
                        expression: "formData.contKndCd",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "contKndCd",
                      id: "contKndCd_2",
                      value: "04",
                    },
                    domProps: { checked: _vm._q(_vm.formData.contKndCd, "04") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.formData, "contKndCd", "04")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "contKndCd_2" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.NEWB010150.005"))),
                  ]),
                ]),
                _c("li", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.contKndCd,
                        expression: "formData.contKndCd",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "contKndCd",
                      id: "contKndCd_3",
                      value: "03",
                    },
                    domProps: { checked: _vm._q(_vm.formData.contKndCd, "03") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.formData, "contKndCd", "03")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "contKndCd_3" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.NEWB010150.006"))),
                  ]),
                ]),
                _c("li", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.contKndCd,
                        expression: "formData.contKndCd",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "contKndCd",
                      id: "contKndCd_4",
                      value: "01",
                    },
                    domProps: { checked: _vm._q(_vm.formData.contKndCd, "01") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.formData, "contKndCd", "01")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "contKndCd_4" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.NEWB010150.007"))),
                  ]),
                ]),
                _c("li", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.contKndCd,
                        expression: "formData.contKndCd",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "contKndCd",
                      id: "contKndCd_5",
                      value: "05",
                    },
                    domProps: { checked: _vm._q(_vm.formData.contKndCd, "05") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.formData, "contKndCd", "05")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "contKndCd_5" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.NEWB010150.008"))),
                  ]),
                ]),
                _c("li", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.contKndCd,
                        expression: "formData.contKndCd",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "contKndCd",
                      id: "contKndCd_6",
                      value: "06",
                    },
                    domProps: { checked: _vm._q(_vm.formData.contKndCd, "06") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.formData, "contKndCd", "06")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "contKndCd_6" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.NEWB010150.009"))),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "mt10 position_relative" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.rmk,
                      expression: "formData.rmk",
                    },
                  ],
                  attrs: { id: "rmk" },
                  domProps: { value: _vm.formData.rmk },
                  on: {
                    keyup: function ($event) {
                      return _vm.checkValidationRemark($event, 300)
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.formData, "rmk", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _vm._m(0),
              ]),
            ]),
            _c("div", { staticClass: "desc_txt mt15" }, [
              _vm._v(_vm._s(_vm.$t("msg.NEWB010150.010"))),
            ]),
            _c("div", { staticClass: "form_box mb20" }, [
              _c(
                "ul",
                {
                  staticClass: "grid service_rdo",
                  attrs: { id: "grdScoreBox" },
                },
                [
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.grdScore,
                          expression: "formData.grdScore",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "grdScore",
                        id: "grdScore_1",
                      },
                      domProps: {
                        value: _vm.score.condItemVal1,
                        checked: _vm._q(
                          _vm.formData.grdScore,
                          _vm.score.condItemVal1
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.checkValidationScore()
                        },
                        change: function ($event) {
                          return _vm.$set(
                            _vm.formData,
                            "grdScore",
                            _vm.score.condItemVal1
                          )
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "grdScore_1" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.KMTC413.021"))),
                    ]),
                  ]),
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.grdScore,
                          expression: "formData.grdScore",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "grdScore",
                        id: "grdScore_2",
                      },
                      domProps: {
                        value: _vm.score.condItemVal2,
                        checked: _vm._q(
                          _vm.formData.grdScore,
                          _vm.score.condItemVal2
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.checkValidationScore()
                        },
                        change: function ($event) {
                          return _vm.$set(
                            _vm.formData,
                            "grdScore",
                            _vm.score.condItemVal2
                          )
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "grdScore_2" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.KMTC413.022"))),
                    ]),
                  ]),
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.grdScore,
                          expression: "formData.grdScore",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "grdScore",
                        id: "grdScore_3",
                      },
                      domProps: {
                        value: _vm.score.condItemVal3,
                        checked: _vm._q(
                          _vm.formData.grdScore,
                          _vm.score.condItemVal3
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.checkValidationScore()
                        },
                        change: function ($event) {
                          return _vm.$set(
                            _vm.formData,
                            "grdScore",
                            _vm.score.condItemVal3
                          )
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "grdScore_3" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.KMTC413.023"))),
                    ]),
                  ]),
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.grdScore,
                          expression: "formData.grdScore",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "grdScore",
                        id: "grdScore_4",
                      },
                      domProps: {
                        value: _vm.score.condItemVal4,
                        checked: _vm._q(
                          _vm.formData.grdScore,
                          _vm.score.condItemVal4
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.checkValidationScore()
                        },
                        change: function ($event) {
                          return _vm.$set(
                            _vm.formData,
                            "grdScore",
                            _vm.score.condItemVal4
                          )
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "grdScore_4" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.KMTC413.024"))),
                    ]),
                  ]),
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.grdScore,
                          expression: "formData.grdScore",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "grdScore",
                        id: "grdScore_5",
                      },
                      domProps: {
                        value: _vm.score.condItemVal5,
                        checked: _vm._q(
                          _vm.formData.grdScore,
                          _vm.score.condItemVal5
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.checkValidationScore()
                        },
                        change: function ($event) {
                          return _vm.$set(
                            _vm.formData,
                            "grdScore",
                            _vm.score.condItemVal5
                          )
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "grdScore_5" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.KMTC413.025"))),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "text_center mt20" }, [
          _c(
            "a",
            {
              staticClass: "button lg blue font_16",
              attrs: { href: "#none" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.vocUpdate()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.NEWB010150.021")))]
          ),
          _c(
            "a",
            {
              staticClass: "button lg gray font_16",
              attrs: { href: "#none" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.layerClose()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.NEWB010150.022")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "div_req_byte" }, [
      _c(
        "span",
        { staticClass: "color_black font_medium", attrs: { id: "byte_rmk" } },
        [_vm._v("0")]
      ),
      _c("span", { staticClass: "color_lightgray" }, [_vm._v("/")]),
      _c("span", { staticClass: "color_lightgray" }, [_vm._v("300")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }