<template>
  <div class="popup_wrap" style="width:480px; height:auto;">
    <button class="layer_close" @click="layerClose()">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.NEWB010150.001') }}</h1><!-- KMTC VOC -->
      <div class="content_box">
        <form id="vocForm">
          <div class="tit_txt">{{ $t('msg.NEWB010150.002') }}</div><!-- 평소 고려해운을 이용해 주셔서 감사합니다. -->
          <div class="desc_txt mt15">{{ $t('msg.NEWB010150.003') }}</div><!-- 서비스 개선이 필요한 부분을 제안해 주세요. -->
          <div class="service_form_wrap">
            <ul class="grid" id="contKndCdBox">
              <li>
                <input
                  type="radio"
                  name="contKndCd"
                  id="contKndCd_1"
                  v-model="formData.contKndCd"
                  value="02"
                >
                <label for="contKndCd_1">{{ $t('msg.NEWB010150.004') }}</label><!-- 고객응대 -->
              </li>
              <li>
                <input
                  type="radio"
                  name="contKndCd"
                  id="contKndCd_2"
                  v-model="formData.contKndCd"
                  value="04"
                >
                <label for="contKndCd_2">{{ $t('msg.NEWB010150.005') }}</label><!-- 컨테이너 -->
              </li>
              <li>
                <input
                  type="radio"
                  name="contKndCd"
                  id="contKndCd_3"
                  v-model="formData.contKndCd"
                  value="03"
                >
                <label for="contKndCd_3">{{ $t('msg.NEWB010150.006') }}</label><!-- 영업관리 -->
              </li>
              <li>
                <input
                  type="radio"
                  name="contKndCd"
                  id="contKndCd_4"
                  v-model="formData.contKndCd"
                  value="01"
                >
                <label for="contKndCd_4">{{ $t('msg.NEWB010150.007') }}</label><!-- 스케줄 -->
              </li>
              <li>
                <input
                  type="radio"
                  name="contKndCd"
                  id="contKndCd_5"
                  v-model="formData.contKndCd"
                  value="05"
                >
                <label for="contKndCd_5">{{ $t('msg.NEWB010150.008') }}</label><!-- 시스템 -->
              </li>
              <li>
                <input
                  type="radio"
                  name="contKndCd"
                  id="contKndCd_6"
                  v-model="formData.contKndCd"
                  value="06"
                >
                <label for="contKndCd_6">{{ $t('msg.NEWB010150.009') }}</label><!-- 기타 -->
              </li>
            </ul>
            <div class="mt10 position_relative">
              <textarea
                id="rmk"
                v-model="formData.rmk"
                @keyup="checkValidationRemark($event, 300)"
              />
              <div class="div_req_byte">
                <span class="color_black font_medium" id="byte_rmk">0</span>
                <span class="color_lightgray">/</span>
                <span class="color_lightgray">300</span>
              </div>
            </div>
          </div>
          <div class="desc_txt mt15">{{ $t('msg.NEWB010150.010') }}</div><!-- 서비스 만족도를 선택해 주세요. -->
          <div class="form_box mb20">
            <ul class="grid service_rdo" id="grdScoreBox">
              <li>
                <input
                  type="radio"
                  name="grdScore"
                  id="grdScore_1"
                  :value="score.condItemVal1"
                  v-model="formData.grdScore"
                  @click="checkValidationScore()"
                >
                <label for="grdScore_1">{{ $t('msg.KMTC413.021') }}</label><!-- 매우 만족 -->
              </li>
              <li>
                <input
                  type="radio"
                  name="grdScore"
                  id="grdScore_2"
                  :value="score.condItemVal2"
                  v-model="formData.grdScore"
                  @click="checkValidationScore()"
                >
                <label for="grdScore_2">{{ $t('msg.KMTC413.022') }}</label><!-- 만족 -->
              </li>
              <li>
                <input
                  type="radio"
                  name="grdScore"
                  id="grdScore_3"
                  :value="score.condItemVal3"
                  v-model="formData.grdScore"
                  @click="checkValidationScore()"
                >
                <label for="grdScore_3">{{ $t('msg.KMTC413.023') }}</label><!-- 보통 -->
              </li>
              <li>
                <input
                  type="radio"
                  name="grdScore"
                  id="grdScore_4"
                  :value="score.condItemVal4"
                  v-model="formData.grdScore"
                  @click="checkValidationScore()"
                >
                <label for="grdScore_4">{{ $t('msg.KMTC413.024') }}</label><!-- 불만족 -->
              </li>
              <li>
                <input
                  type="radio"
                  name="grdScore"
                  id="grdScore_5"
                  :value="score.condItemVal5"
                  v-model="formData.grdScore"
                  @click="checkValidationScore()"
                >
                <label for="grdScore_5">{{ $t('msg.KMTC413.025') }}</label><!-- 매우 불만족 -->
              </li>
            </ul>
          </div>
        </form>
      </div>
      <div class="text_center mt20">
        <a
          href="#none"
          class="button lg blue font_16"
          @click.prevent="vocUpdate()"
        >{{ $t('msg.NEWB010150.021') }}</a><!-- 확인 -->
        <a
          href="#none"
          class="button lg gray font_16"
          @click.prevent="layerClose()"
        >{{ $t('msg.NEWB010150.022') }}</a><!-- 취소 -->
      </div>
    </div>
  </div>
</template>

<script>
// TODO
// docNo 부킹넘버로사용
// 특수문자, 하루동안 창 열지않는 기능 안써서 제외

import bookings from '@/api/rest/trans/bookings'

export default ({
  name: 'BookingvocPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          docNo: ''
        }
      }
    }
  },
  data () {
    return {
      score: {},
      formData: {
        contKndCd: '',
        rmk: '',
        usrEmlAddr: '',
        grdScore: '',
        docNo: ''
      }
    }
  },
  created () {
    this.formData.docNo = this.$route.query.docNo
    document.body.style = 'min-width: 480px !important; height: auto;'
  },
  mounted () {
    this.getvoc()
  },
  methods: {
    layerClose: function () {
      this.$emit('close')
      return false
    },
    getvoc: function () {
      bookings.getvoc().then((result) => {
        const res = result.data
        this.score = res.score
      })
      .catch(err => {
        console.log(err)
      })
    },
    vocUpdate: function () {
      const isOk = this.checkValidationAll()

      if (!isOk) {
        return
      }

      bookings.postvoc(this.formData).then((result) => {
        const alertOpt = {
          alertType: 'simple',
          customCloseBtnText: this.$t('msg.NEWB010150.023') // 확인
        }

        if (result.headers.respcode === 'C0000') {
          this.$ekmtcCommon.alert({
            ...alertOpt,
            message: this.$t('msg.KMTC413.019'), // 설문에 응하여 주셔서 감사합니다.,
            onClose: () => { window.close() }
          })
        } else {
          this.$ekmtcCommon.alert({
            ...alertOpt,
            message: this.$t(result.headers.respmsg)
          })
        }
      })
      .catch(err => {
        console.log(err)
      })
    },
    checkValidationAll () {
      const frm = document.querySelector('#vocForm')
      let isAllOk = true

      // 서비스 개선
      // remark byte check
      // 선택 사항이지만, 입력 값의 크기가 기준을 초과하면 DB INSERT Error가 나기 때문에 체크
      const byte = document.querySelector('#byte_rmk').innerHTML
      if (Number(byte.replace(/,/g, '')) > 300) {
        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#rmk'), this.$t('msg.CSBK100.182')) // 내용이 너무 많습니다.
        isAllOk = false
      }

      // 서비스 만족도
      if (!this.checkValidationScore(frm)) {
        isAllOk = false
      }

      return isAllOk
    },
    checkValidationScore (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#vocForm')
      }

      const elem = frm.querySelector('#grdScoreBox')
      const arrChk = frm.querySelectorAll('*[name="grdScore"]:checked')
      let isOk = true

      if (arrChk.length === 0) {
        isOk = false
      }

      // if (!isOk) {
      //   this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.NEWB010150.016')) // 항목을 선택하여 주십시오.
      // } else {
      //   this.$ekmtcCommon.hideErrorTooltip(elem)
      // }

      if (!isOk) {
        const alertOpt = {
          alertType: 'simple',
          customCloseBtnText: this.$t('msg.NEWB010150.023') // 확인
        }

        this.$ekmtcCommon.alert({
          ...alertOpt,
          message: this.$t('msg.NEWB010150.024') // 만족도를 선택해 주세요.
        })
      }

      return isOk
    },
    checkValidationRemark (event, maxlength) {
      let isOk = true
      const id = event.target.id
      const frm = document.querySelector('#vocForm')
      const elem = frm.querySelector('#' + id)

      this.$ekmtcCommon.hideErrorTooltip(elem)

      if (this.$ekmtcCommon.fnKeyupByteCheck(event, maxlength) > maxlength) {
        this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.CSBK100.182')) // 내용이 너무 많습니다.
        isOk = false
      }

      return isOk
    }
  }
})
</script>

<style scoped>
.div_req_byte {text-align:right; margin-top:2px;}
/* .email_sec label+input[type="text"] {margin-left:15px; margin-right:2px;}
.form_box{height: auto;}
#vocForm .grid.service_rdo li{margin-left: 13px;margin-right: 0px;position: relative;}
#vocForm .grid.service_rdo li:first-child{margin-left: 0px;}
.popup_wrap {border-radius: none !important; box-shadow: none !important;} */
</style>
